export const experienceData = [
    {
        id: 1,
        company: 'Web Developer Freelancer',
        jobtitle: 'Full-Stack Web Developer, Marketing',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'OnCuba News',
        jobtitle: 'Web editor and multimedia content manager, journalist',
        startYear: '2015',
        endYear: '2021'
    },
    // {
    //     id: 3,
    //     company: 'Wonka Industries',
    //     jobtitle: 'Marketing',
    //     startYear: '2020',
    //     endYear: 'Present'
    // },
]