// import one from '../assets/svg/projects/one.svg'
// import two from '../assets/svg/projects/two.svg'
// import three from '../assets/svg/projects/three.svg'
// import four from '../assets/svg/projects/four.svg'
// import five from '../assets/svg/projects/five.svg'
// import six from '../assets/svg/projects/six.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'
import bookworm from '../assets/svg/projects/bookwormdesign.png'
import petwatch from '../assets/png/petwatch.png'
import plutoweb from '../assets/png/plutoweb.jpg'
import growtopia from '../assets/png/growtopia.jpg'


export const projectsData = [
    {
        id: 1,
        projectName: 'BookWorm',
        projectDesc: 'Bookworm is a web app for collecting and tracking the reading of NYT Best Seller books while allowing the user to create their own collections.',
        tags: ['React', 'CSS', 'Node JS', 'MySQL'],
        code: 'https://github.com/macapedi',
        demo: 'https://www.loom.com/share/b98860e5a4a049eca593f3704da6fe0d',
        image: bookworm
    },
    {
        id: 2,
        projectName: 'PetWatch',
        projectDesc: 'An app to helping humans to find their furry family members using a notification system for alerts.',
        tags: ['React', 'AWS Amplify'],
        code: 'https://github.com/paulbr06/petwatchone',
        demo: 'https://petwatch.one',
        image: petwatch
    },
    {
        id: 3,
        projectName: 'PlutoWeb',
        projectDesc: 'Pluto Web is a web-based application that simplifies html & css code-writing by generating code from simple English commands.',
        tags: ['React', 'CSS', 'NPM', 'JavaScript'],
        code: 'https://github.com/macapedi/pluto-web',
        demo: 'https://devpost.com/software/pluto-web',
        image: plutoweb
    },
    {
        id: 4,
        projectName: 'Growtopia',
        projectDesc: 'This project involves the development of a web application for managing a sustainable garden.',
        tags: ['React', 'AWS Amplify'],
        code: 'https://github.com/cdukedev/growtopia',
        demo: 'https://devpost.com/software/growtopia?ref_content=user-portfolio&ref_feature=in_progress',
        image: growtopia
    },
    // {
    //     id: 5,
    //     projectName: 'E-Commerce App',
    //     projectDesc: 'A Simple E-commerce application',
    //     tags: ['React Native', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: five
    // },
    // {
    //     id: 6,
    //     projectName: 'Uber Lite',
    //     projectDesc: 'Uber clone',
    //     tags: ['Flutter'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: six
    // },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/