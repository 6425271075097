export const educationData = [
    {
        id: 1,
        institution: 'BrainStation Bootcamp',
        course: 'Web Development',
        startYear: 'May, 2022',
        endYear: 'August, 2022'
        
    },
    {
        id: 2,
        institution: 'Syracuse University',
        course: 'Master in Arts, Digital Journalism',
        startYear: '2017',
        endYear: '2018'
    },
    {
        id: 3,
        institution: 'University of Havana',
        course: 'Bachelor of Arts, Journalism',
        startYear: '2008',
        endYear: '2013'
    },
]