import resume from '../assets/pdf/resume.pdf'
import profilepic from '../assets/png/profilepic2.jpg'

export const headerData = {
    name: 'Marita Perez Diaz',
    title: "Full-Stack Software Engineer",
    desciption:"Web developer and multi-hackathon winner with a solid background in content creation. Curious and fast-learner. As a bilingual Cuban immigrant with strong skills in problem-solving and effective communication,  I believe technology is a natural continuation of my love for ﬁnding solutions and creatively building products that communicate value and help people.",
    image: profilepic,
    resumePdf: resume
}
