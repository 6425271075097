import petwatch from '../assets/png/petwatch.png';
import plutoweb from '../assets/png/plutoweb.jpg';
import growtopia from '../assets/png/growtopia.jpg';


export const achievementData = {
    bio : "I love learning new technologies and keeping my coding skills sharp by participating in hackathons. These are some of my main accomplishments there.",
    achievements : [
        {
            id : 1,
            title : '2nd Place Pluto Hacks',
            details : 'My team "Pluto Web" earned the Second Place Overall in the Pluto Hacks Hackathon made by Broward College.',
            date : 'October, 2022',
            field : 'Web Development',
            image : plutoweb
        },
        {
            id : 2,
            title : '3rd Place South Florida Tech Hub Hackathon',
            details : 'PetWatch won the Third Place. This was an app that created "amber alerts" for lost pets',
            date : 'September, 2022',
            field : 'Web Development',
            image : petwatch
        },
        {
            id : 3,
            title : '3rd Place Power to Fly Hackathon',
            details : 'We created Growtopia, an app to help people to plant sustainable gardens and maintain them',
            date : 'December, 2022',
            field : 'Web Development',
            image : growtopia
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/